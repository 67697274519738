import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

const styles = {
  footer: {
    background: "#2a9d8f",
    width: "100%",
    color: "blue",
    position: 'fixed',
    bottom: "0",
  },
};

const Footer = () => {
  return (
    <div style={styles.footer}>
      <Container fluid="true">
        <Row
          style={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            padding: '1rem'
          }}
        >
          <p> Social Media</p>
          <p> Copyright ©2023 Pixel Heads</p>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
