import React from "react";
import Header from "../../components/Navigation";
import Footer from "../../components/Footer";
import Hero from "../../images/Logo 1.png";
import Arrow from "../../images/ben-robbins-I0iaRFzFJZM-unsplash.jpg";
import { CardGroup, Container, Row } from "react-bootstrap";
import RoyalPearl from "../../components/CaseStudy/RoyalPearl";
import { RentTonies } from "../../components/CaseStudy/RentTonies";
import MessageModal from "../../components/MessageModal";
import CaseStudyCard from "../../components/CaseStudy/CaseStudyCard";
import RentToniesCaseStudyCard from "../../components/CaseStudy/CaseStudyCard";
import Services from "../../components/Services";
import Process from "../../components/Process";
import Tools from "../../components/Tools";

const styles = {
  row: {
    justifyContent: "center",
  },
  parallax: {
    perspective: "1px",
    height: "100vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  parallaxBackground: {
    backgroundImage: `url(${Hero})`,
    minHeight: "100vh",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  // Services
  parallaxLayer1: {
    position: "absolute",
    top: "155rem",
    right: 0,
    left: 0,
    height: "28rem",
    transform: "translateZ(-4px) scale(5)",
    background: "orange",
  },
  // Process
  parallaxLayer2: {
    position: "absolute",
    top: "220rem",
    bottom: "0",
    right: 0,
    left: 0,
    height: "28rem",
    transform: "translateZ(-2px) scale(3) ",
    background: "green",
  },
  // Tools
  parallaxLayer3: {
    position: "absolute",
    top: "270rem",
    right: 0,
    left: 0,
    height: "28rem",
    bottom: "0rem",
    transform: "translateZ(-1px) scale(2)",
    background: "blue",
    marginBottom: "-50rem",
  },
  arrowContainer: {
    display: "flex",
    justifyContent: "center",
  },
  arrowImage: {
    backgroundImage: `url(${Arrow})`,
    width: "100%",
    height: "auto",
backgroundSize: "cover",
    minHeight: "100vh",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
};

const Home = () => {
  return (
    <>
      <MessageModal />

      <div style={styles.parallax}>
        <Container
          fluid
          className="m-0 p-0"
          style={styles.parallaxBackground}
        />

        <div
          style={{
            position: "relative",
            transformStyle: "preserve-3d",
          }}
        >
          <div style={styles.parallaxLayer1}>
            <Services />
          </div>
          <div style={styles.parallaxLayer2}>
            <Process />
          </div>
          <div style={styles.parallaxLayer3}>
            <Tools />
          </div>
          </div>
        <Footer />
        </div>
          <div style={styles.arrowContainer}>
            <div style={styles.arrowImage} />
      </div>
    </>
  );
};

export default Home;
