import React from "react";


const Services = () => {
  return (
    <div >
      <h2 className="text-center">SERVICES</h2>
<p>Utilize React | Firebase | </p>
      <br></br>
    </div>
  );
};

export default Services;
