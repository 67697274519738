import React from 'react'




const Process = () => {
  return (
    <div >
       <h2 className="text-center">PROCESS</h2>
<p>Discover what the client needs</p>
      <br></br>
    </div>
  )
}

export default Process
