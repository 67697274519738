import React, { useRef, useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import emailjs from "@emailjs/browser";

const styles = {
  button: {
    position: "fixed",
    bottom: "30px",
    right: "30px",
    zIndex: "99",
    fontSize: "1.5rem",
    borderRadius: "0px",
    border: "1px solid black",
  },
  offcanvasHeader: {
    placement: "end",
  },
};

const MessageModal = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [phone, setPhone] = useState("");
  const [info, setInfo] = useState("");

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setInfo("");
    localStorage.removeItem("items");
  };

  const form = useRef();

  const handleEmailChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setEmail(value);
    setIsEmailValid(value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
  };

  // emailJS send email handler
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6bs8wrb",
        "template_3j7jhil",
        form.current,
        "JEjSNvfLzH0NMTIyH"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your Message has been sent");
          resetForm();
          setShow(false);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <Button
        variant=""
        style={styles.button}
        onClick={handleShow}
        className="me-2"
      >
        Contact Us
      </Button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        scroll="true"
        autoFocus="true"
        style={{ margin: "1rem" }}
      >
        <Offcanvas.Header style={styles.offcanvasHeader} />

        <Form onSubmit={handleSubmit} ref={form}>
          <Form.Group style={{ margin: "1rem" }}>
            <Form.Label className="text-align-center">
              How can we provide value?
            </Form.Label>
          </Form.Group>

          <Form.Group style={{ padding: "5px" }}>
            <Form.Control
              required
              type="text"
              name="Name"
              placeholder="What's your name?"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>

          <Form.Group style={{ padding: "5px" }}>
            <Form.Control
              required
              type="email"
              name="Email"
              placeholder="What's your email address?"
              onChange={handleEmailChange}
            />
          </Form.Group>

          <Form.Group style={{ padding: "5px" }}>
            <Form.Control
              required
              type="tel"
              name="Phone Number"
              placeholder="What's your phone number?"
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>

          <Form.Group style={{ padding: "5px" }}>
            <Form.Control
              type="text"
              as="textarea"
              rows={3}
              name="Info"
              placeholder="What is your biggest problem?"
              style={{ width: "100%" }}
              onChange={(e) => setInfo(e.target.value)}
            />
          </Form.Group>

          <Button type="submit" style={styles.button} variant="">
            Submit
          </Button>
        </Form>
      </Offcanvas>
    </>
  );
};

export default MessageModal;
