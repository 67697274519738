import React from 'react'

const Tools = () => {
  return (
    <div className='text-center'>
      <h2> TOOLS </h2>
    </div>
  )
}

export default Tools
